import { IHttpClient } from '@wix/yoshi-flow-editor';
import { ORDER_API_PATH, PRODUCT_API_PATH } from './constants';
import { getBusinessDataLink, getPremiumStatusLink } from './links';

// TODO: add types for arguments and return values

export async function makeRequestForOrder(
  httpClient: IHttpClient,
  instance: string,
  productId: string,
): Promise<any> {
  return httpClient
    .post(
      ORDER_API_PATH,
      { productId },
      { headers: { Authorization: instance } },
    )
    .then((resp) => resp.data);
}

export const createProduct = (
  httpClient: IHttpClient,
  instance: string,
  data: any,
): Promise<any> => {
  return httpClient
    .post(PRODUCT_API_PATH, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const getProduct = (
  httpClient: IHttpClient,
  instance: string,
  productId: string,
): Promise<any> => {
  return httpClient
    .get(`${PRODUCT_API_PATH}/${productId}`, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data);
};

export const updateProduct = (
  httpClient: IHttpClient,
  instance: string,
  data: any,
  productId: string,
) => {
  return httpClient
    .put(`${PRODUCT_API_PATH}/${productId}`, data, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data);
};

export const updateProductCurrencyFromSettings = async (
  httpClient: IHttpClient,
  productId: string,
  instance: string,
) => {
  const [
    product,
    {
      properties: { paymentCurrency: currency },
    },
  ] = await Promise.all([
    getProduct(httpClient, instance, productId),
    getBusinessData(httpClient, instance),
  ]);
  product.product.currency = currency;
  return updateProduct(httpClient, instance, product, productId);
};

export const getBusinessData = (
  httpClient: IHttpClient,
  instance: string,
): Promise<any> => {
  return httpClient
    .get(getBusinessDataLink(), { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const getPremiumStatus = (
  httpClient: IHttpClient,
  instance: string,
): Promise<boolean> => {
  return httpClient
    .get(getPremiumStatusLink(), { headers: { Authorization: instance } })
    .then((resp) => (resp as any).data.canAcceptOnlinePayments)
    .catch(() => true);
};
