
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/platform';

            import controller0 from '/home/builduser/work/65272e1ccdb126e5/packages/pay-button/src/components/button/controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/65272e1ccdb126e5/packages/pay-button/src/components/button/controller.ts';
            var controllerExport0 = _controllerExport0;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://8c30b2581acc426badc414e8a5dc04ef@sentry.wixpress.com/2193',
      id: '8c30b2581acc426badc414e8a5dc04ef',
      projectName: 'pay-button',
      teamName: 'payments',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["payments","checkout"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/65272e1ccdb126e5/packages/pay-button/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"settingsPanel.title":"Pay Button Settings","buttonGFPP.mainAction1":"Settings","buttonGFPP.mainAction2":"Manage Payment Methods","notificationSSL.title":"Securing your site with SSL will give your customers confidence at Checkout and can boost your SEO.","notificationSSL.link":"Manage SSL","item.defaultName":"I'm an item"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/65272e1ccdb126e5/packages/pay-button/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "pay-button",
    biConfig: null,
    appName: "Pay Button",
    appDefinitionId: "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "PLATFORM_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["payments","checkout"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/65272e1ccdb126e5/packages/pay-button/src/components/button/controller.ts",
          appName: "Pay Button",
          appDefinitionId: "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
          projectName: "pay-button",
          componentName: "button",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "WixPay" }],
    true);

    export const createControllers = _createControllers
